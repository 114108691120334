<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router';

const checkoutRoutes = [
    'checkout-step-1-cart',
    'checkout-step-2-booking',
] as RouteLocationRaw[];

const steps = computed(() => {
    return [
        ...useFlowStore().productConfig?.steps.map(step => step.routeName) ?? [],
        ...checkoutRoutes,
    ];
});

const currentRoute = computed(() => getRouteName(useRoute()));
const totalDots = computed(() => steps.value.length);
const currentDot = computed(() => steps.value.indexOf(currentRoute.value) + 1);
</script>

<template>
    <ul class="absolute right-container-gap top-8 z-20 flex space-x-1 sm:top-11 md:top-[25px]">
        <li
            v-for="dot in totalDots"
            :key="dot"
            class="size-[5px] rounded-full transition-[width,background-color] duration-700"
            :class="{
                'w-[30px]': dot === currentDot,
                'bg-shade-5': dot <= currentDot,
                'bg-shade-3': dot > currentDot,
            }"
        />
    </ul>
</template>
