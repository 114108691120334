<script setup lang="ts">
const {
    isEnabled: isFooterEnabled,
} = useFooterState();
</script>

<template>
    <div>
        <TheBaseLayout>
            <div
                class="
                    grid
                    h-full
                    grid-rows-[auto_min-content]
                    bg-background-1
                "
            >
                <div class="relative">
                    <slot name="logo">
                        <TheLogo />
                    </slot>
                    <slot name="progressDots">
                        <TheProgressDots />
                    </slot>
                    <slot name="main">
                        <!--
                            TODO:
                            Suspense prevents double mount of pages. why? no fucking clue
                            https://github.com/nuxt/nuxt/issues/30200
                        -->
                        <Suspense>
                            <NuxtPage />
                        </Suspense>
                    </slot>
                </div>

                <div :class="isFooterEnabled ? 'h-[68px] md:h-[76px]' : 'h-0'">
                    <slot name="footer">
                        <TheFooter />
                    </slot>
                </div>
            </div>
        </TheBaseLayout>
    </div>
</template>
